import Link from 'next/link';
import { isValidElement, useCallback, useMemo } from 'react';

import cn from 'classnames';

import Arrow from '@publicImages/svg/arrow-right.svg?icon';

import type { Port } from '@/infra/types/voyageInfo/port';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import AlmostSoldOut from '@/components/AlmostSoldOut';
import * as Dialog from '@/components/elements/Dialog';
import { DesktopOnly, MobileOnly, Screen } from '@/components/elements/Helpers';
import Price from '@/components/elements/Price';
import UIResource from '@/components/UIResource';
import { CABIN_CATEGORY_CODES } from '@/constants/cabinCategoryCodes';
import { useUIResourcePool } from '@/ducks/common/resources';
import { selectConfigServiceFeatureSpecialSailings } from '@/ducks/common/settings/selectors';
import { useGetSpecialSailings } from '@/ducks/lookup/hooks/useGetSpecialSailings';
import { findSwappedPort } from '@/ducks/pages/chooseVoyage/groupPackagesWithSameNames';
import useStrikethrough from '@/ducks/programs/strikethrough/useStrikethrough';
import { useIsPromoActive } from '@/features/promo/lib/useIsPromoActive';
import { PromoMessage } from '@/features/promo/PromoMessage';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { formatDate } from '@/helpers/util/dateUtil';
import { getSpecialSailingName } from '@/helpers/util/misc';
import { useIsMobile } from '@/hooks/useIsDevice';
import usePrecheckoutUrlWithParams from '@/hooks/usePrecheckoutPath';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import { SpecialSailingModal } from '../SpecialSailingModal';

import styles from './PricedSailingCard.module.scss';

export const UI_RESOURCE_POOL = {
  desktopText: 'VoyageCard.onlySuitesRemaining',
  mobileText: 'VoyageCard.onlySuitesLeft',
} as const;
type Props = {
  cabins: number;
  className?: string;
  defaultPackagePorts: Port[];
  isPortChangeAvailable: boolean;
  onClick?: (sailing: SailingFromPackages) => void;
  priceType: FiltersPriceType;
  sailing: SailingFromPackages;
  sailors: number;
};

export const PricedSailingCard = ({
  cabins = 1,
  className,
  defaultPackagePorts,
  isPortChangeAvailable,
  onClick,
  priceType,
  sailing,
  sailors = 2,
}: Props) => {
  const { classificationCodes, duration, endDate, id, packageCode, ports, startDate, startingPrice } = sailing;
  const specialSailings = useGetSpecialSailings();
  const hasSpecialSailingFeature = useAppSelector(selectConfigServiceFeatureSpecialSailings);

  const price = useMemo(
    () =>
      priceType === FiltersPriceType.sailorPerNight
        ? getAmountPerVoyage(startingPrice, duration, { sailors })
        : getAmountPerItem(startingPrice, { cabins, priceType, sailors }),
    [cabins, duration, priceType, sailors, startingPrice],
  );
  const specialSailingName = useMemo(
    () => getSpecialSailingName(classificationCodes, specialSailings),
    [classificationCodes, specialSailings],
  );

  const isSpecialSailing = !!specialSailingName && hasSpecialSailingFeature;

  const { currencyCode } = startingPrice || {};

  const getStrikethroughForPackage = useStrikethrough(classificationCodes);
  const strikethroughValue = getStrikethroughForPackage(startingPrice?.minPricedCategoryCode);

  let originalAmount;
  if (strikethroughValue) {
    originalAmount = price + strikethroughValue;
  }

  const isPromoEnabled = useIsPromoActive('sailingItem', sailing);
  const shouldColorOriginalPrice = isPromoEnabled && !strikethroughValue;

  const swappedPort = useMemo(
    () => isPortChangeAvailable && findSwappedPort(defaultPackagePorts || [], ports),
    [defaultPackagePorts, isPortChangeAvailable, ports],
  );
  const isPortSwap = isValidElement(swappedPort);

  const urlPath = usePrecheckoutUrlWithParams(sailing);

  const onClickHandler = useCallback(() => {
    onClick?.(sailing);
  }, [onClick, sailing]);

  const leadCabin = sailing?.cabinCategoryCode;
  const isSuite = [CABIN_CATEGORY_CODES.MEGA_ROCKSTAR, CABIN_CATEGORY_CODES.ROCKSTAR_SUITES].includes(leadCabin);
  const { desktopText, mobileText } = useUIResourcePool(UI_RESOURCE_POOL);
  const isMobile = useIsMobile();
  const label = isMobile ? `${Screen.MOBILE}_${id}` : `${Screen.DESKTOP}_${id}`;

  return (
    <div className={styles.root}>
      <Dialog.Root>
        <Link
          aria-labelledby={id}
          className={cn(styles.pricedSailingCard, className, {
            [styles.isSpecialSailing]: isSpecialSailing || isPromoEnabled,
            [styles.portSwap]: isPortSwap,
            [styles.promoSailingCard]: isPromoEnabled,
          })}
          data-id={id}
          data-packageid={packageCode}
          href={urlPath}
          onClick={onClickHandler}
          prefetch={false}
          tabIndex={0}
          {...(isSuite ? { 'aria-describedby': label } : '')}
        >
          <div className={styles.pricedSailingCardHeader}>
            <div>
              <div className={styles.dates} id={id}>
                <AlmostSoldOut
                  classificationCodes={sailing.classificationCodes}
                  className={styles.almostSoldOut}
                  messageId="AlmostSoldOut.SailingLevel.Emoji"
                  withoutStyles
                />
                <span className={styles.date}>{formatDate(startDate, 'E, MMM dd')}</span>
                {' - '}
                <span className={styles.date}>{formatDate(endDate, 'E, MMM dd')}</span>

                <AlmostSoldOut
                  classificationCodes={sailing.classificationCodes}
                  className="sr-only"
                  messageId="AlmostSoldOut.lastRemainingCabins"
                  withoutStyles
                />
              </div>
            </div>

            <div>
              <div className={styles.priceAndArrow}>
                <span className={styles.price}>
                  <span className={styles.priceFromLabel}>
                    <UIResource id="Cabins.Lead.Price.label.from" />{' '}
                  </span>
                  <span
                    className={cn(styles.price, {
                      [styles.promoPrice]: shouldColorOriginalPrice,
                    })}
                  >
                    <Price amount={price} currencyCode={currencyCode} originalAmount={originalAmount} roundUp />
                  </span>
                </span>
                <Arrow />
              </div>
            </div>
          </div>

          {(isSpecialSailing || isPromoEnabled) && (
            <div className={styles.pricedSailingCardPromo}>
              <div>
                {isSpecialSailing && (
                  <Dialog.Isolator>
                    <Dialog.Trigger aria-hidden="true" className={styles.specialSailingModalTrigger} tabIndex={-1}>
                      {specialSailingName}
                    </Dialog.Trigger>
                  </Dialog.Isolator>
                )}
              </div>
              <div>{isPromoEnabled && <PromoMessage className={styles.promoDiscount} />}</div>
            </div>
          )}

          <AlmostSoldOut
            classificationCodes={sailing.classificationCodes}
            className={styles.almostSoldOutMessage}
            messageId="AlmostSoldOut.lastRemainingCabins"
            withoutStyles
          />

          {isPortSwap && swappedPort}

          {isSuite && (
            <div className={styles.leadCabinText}>
              <DesktopOnly id={`${Screen.DESKTOP}_${id}`}>{desktopText}</DesktopOnly>
              <MobileOnly id={`${Screen.MOBILE}_${id}`}>{mobileText}</MobileOnly>
            </div>
          )}
        </Link>

        {isSpecialSailing && (
          <>
            <Dialog.Content
              className={styles.specialSailingModal}
              multipleTriggers
              role="document"
              title={specialSailingName}
            >
              <SpecialSailingModal classificationCode={classificationCodes?.[0]} />
            </Dialog.Content>
            <Dialog.Isolator>
              <Dialog.Trigger className={styles.specialSailingModalTriggerSrOnly}>
                <span className="sr-only">{specialSailingName} more information</span>
              </Dialog.Trigger>
            </Dialog.Isolator>
          </>
        )}
      </Dialog.Root>
    </div>
  );
};
