'use client';

import { useEffect } from 'react';

import cn from 'classnames';

import Trash from '@publicImages/svg/trash.svg?icon';

import IconButton from '@/components/elements/IconButton';
import { getIsAccessKeyStateValid, selectAccessKeySlice } from '@/ducks/accessKeys';
import { accessKeyClear } from '@/ducks/accessKeys/accessKeys';
import { resetAKFlowFully } from '@/ducks/accessKeys/utils';
import { useUIResourcePool } from '@/ducks/common/resources';
import { useAppDispatch, useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import styles from './AccessKey.module.scss';

const MARQUEE_UI_RESOURCE_POOL = {
  clearCTA: 'AccessKey.clearCTA',
  defaultDescription: 'AccessKey.bannerMessage',
  defaultTitle: 'AccessKey.eyebrow.label',
  mobileClearText: 'AccessKey.clearCTA.short',
  promoIcon: 'AccessKey.emoji.key',
  promoText: 'AccessKey.bookShareToast',
  title: 'AccessKey.bannerTitle',
} as const;

function AccessKeyBanner({ className, placeholder }: { className?: string; placeholder?: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const accessKeyDetails = useAppSelector(selectAccessKeySlice);
  const { accessKey, error, shortDescription, state, title } = accessKeyDetails;

  const messages = useUIResourcePool(MARQUEE_UI_RESOURCE_POOL);

  useEffect(() => {
    if (!!error && accessKey == null) dispatch(accessKeyClear());
  }, [error, accessKey, dispatch]);

  const clearAccessKey = () => {
    const buttonText = messages.clearCTA;
    tagmanager.tracker.common.trackIconClick({
      labels: [buttonText],
    });
    dispatch(resetAKFlowFully());
  };

  if (!getIsAccessKeyStateValid(state)) {
    return placeholder;
  }

  return (
    <div className={cn(styles.accessKeyBanner, className)}>
      <div className={styles.description}>
        <span className={styles.icon}>{messages.promoIcon}</span>
        <div>
          <span className={styles.title}>{title ?? messages.defaultTitle} </span>
          &nbsp;
          <span>{shortDescription ?? messages.defaultDescription}</span>
        </div>
      </div>
      <div className={styles.dividedLine}>
        <div className={styles.cta}>
          <IconButton aria-label={messages.clearCTA} className={styles.clearButton} onClick={clearAccessKey}>
            <Trash />
            <span className={styles.label}>{messages.clearCTA}</span>
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default AccessKeyBanner;
