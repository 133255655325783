'use client';

import { forwardRef } from 'react';

import cn from 'classnames';
import { type ContentProps, Drawer as Primitive } from 'vaul';

import CrossIcon from '@publicImages/svg/close-without-bg.svg?static';

import { useIsMobile } from '@/hooks/useIsDevice';

import styles from './Drawer.module.scss';

export const Root = ({ shouldScaleBackground = true, ...props }: React.ComponentProps<typeof Primitive.Root>) => {
  const isMobile = useIsMobile();
  return <Primitive.Root handleOnly={isMobile} shouldScaleBackground={shouldScaleBackground} {...props} />;
};
Root.displayName = 'Drawer';

const clickOnTouch = (e: React.TouchEvent<HTMLButtonElement>) => e.currentTarget.click();
export const Content = forwardRef<
  HTMLDivElement,
  ContentProps & {
    closeSlot?: React.ReactNode;
    innerContentClassName?: string;
    overlayClassName?: string;
  }
>(({ children, className, closeSlot, innerContentClassName, overlayClassName, title, ...props }, ref) => (
  <Primitive.Portal>
    <Primitive.Overlay className={cn(styles.overlay, overlayClassName)} />
    <Primitive.Content className={cn(styles.content, className)} ref={ref} {...props}>
      {closeSlot !== undefined ? (
        closeSlot
      ) : (
        <Primitive.Close
          aria-label={`Close ${title} dialog`}
          autoFocus
          className={styles.close}
          // Making button more responsive on iPhone
          onTouchStart={clickOnTouch}
        >
          <CrossIcon />
        </Primitive.Close>
      )}
      <div className={cn(styles.scrollable, innerContentClassName)}>{children}</div>
    </Primitive.Content>
  </Primitive.Portal>
));
Content.displayName = 'DrawerContent';

export const Trigger = forwardRef<HTMLButtonElement, React.ComponentProps<'button'>>((props, ref) => (
  <Primitive.Trigger aria-expanded={undefined} ref={ref} {...props} />
));

Trigger.displayName = 'DrawerTrigger';

export const Title = Primitive.Title;
export const Description = Primitive.Description;
export const Close = Primitive.Close;
